<template>
  <div class="page-header">
    <div class="page-header-content header-elements-md-inline">
      <div class="page-title d-flex">
        <h4>
          <slot>
            <span class="font-weight-bold">
              <template v-if="$route.params.project">
                Rencana Anggaran Alutista
              </template>
              <template v-else>
                {{ $route.meta.pageTitle }}
              </template>
            </span>
            <span v-if="$route.params.project"> - {{ $route.meta.pageTitle }}</span>
          </slot>
        </h4>
        <a href="#" class="header-elements-toggle text-default d-md-none"><i class="icon-more"></i></a>
      </div>
      <div class="header-elements d-none text-center text-md-left mb-3 mb-md-0">
        <div class="btn-group">
          <slot name="buttons" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const _ = require('lodash') 

export default {
  methods: {
    projectName(name) {
      return _.startCase(name)
    }
  }
}
</script>