<script>
import BoPageHeader from '@/components/BoPageHeader.vue'
import BoFooter from '@/components/BoFooter.vue'

export default {
  components: {
    BoPageHeader, BoFooter,
  },
  data() {
    return {
      radioYesNo: [
        { text: 'Yes', value: 'Y' },
        { text: 'No', value: 'N' },
      ],
      leadOptions: [
        { text: '-- All Leads --', value: '' },
        { text: 'Submitted Lead', value: 'opt1' },
        { text: 'Followed Up', value: 'opt2' },
        { text: 'Closed Lead', value: 'opt3' },
      ]
    }
  },
  computed: {
    isAdd() { return this.$route.params.slug == 'add' }
  },
  methods: {
    starMaker(fullLength, scoreLength) {
      let star = ''
      for (let index = 1; index <= fullLength; index++) {
        star += '<i class="icon-star-full2 '+ (index <= scoreLength ? 'point_yellow' : 'point_gray') +'"></i>'
      }
      return star
    }
  }
}
</script>