<template>
<div class="content-wrapper">
  <bo-page-header></bo-page-header>
  <div class="content pt-0">
    <b-card>
      <b-row>
        <b-col md="8">
          <div class="project_frm_wrap">
            <b-form @submit.prevent="addProject">
              <b-row>
                <b-col md="8">
                  <b-form-group
                    label-for="issueTopic"
                  >
                    <template #label>
                      Topik Isu<small class="mandatory_txt">*</small>
                    </template>
                    <div class="form-group-feedback form-group-feedback-left">
                      <b-form-input placeholder="e.g. rencana anggaran alutista" id="issueTopic" />
                      <div class="form-control-feedback">
                        <i class="icon-file-text2"></i>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group label-for="issueDuration">
                    <template #label>
                      Durasi Penggalian Data <small class="mandatory_txt">*</small>
                    </template>
                    <b-form-radio-group :options="durationOption" v-model="dataDuration" />
                    <!-- <b-input-group>
                      <b-form-input id="issueDuration" placeholder="e.g. Custom Furniture" />
                      <template #append>
                        <b-button variant="light"><i class="icon-plus2 mr-0"></i></b-button>
                      </template>
                    </b-input-group> -->
                  </b-form-group>
                  <b-form-group v-if="dataDuration == 'Y'">
                    <b-input-group>
                      <template #prepend>
                        <span class="input-group-text"><i class="icon-calendar22"></i></span>
                      </template>
                      <date-range-picker
                        class="form-control"
                        control-container-class=""
                        opens="right"
                        append-to-body
                        :date-range="durationDate"
                        style="min-width: 180px"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-button class="bg-indigo-400" type="submit" variant="transparent" v-b-modal>Submit</b-button>
              <b-button class="ml-1" variant="outline-secondary">Cancel</b-button>
            </b-form>
            <b-modal
              centered
              id="addProjectProcess"
              hide-footer
              hide-header
            >
              <div class="wrap_info_loader pb-2">
                <h3>Processing Data... </h3>
                <i class="icon-spinner2 spinner"></i>

                <p><span id="counter">{{ counter }}</span> second(s).</p>
              </div>
            </b-modal>
          </div>
        </b-col>
        <b-col md="4">
          <div class="graphic_info">
            <img class="img-responsive" src="global_assets/images/graphic1.jpg" />
            <h4>Information</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
              et dolore magna aliqua
            </p>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
  <bo-footer></bo-footer>
</div>
</template>
<script>
const _ = require('lodash')
import projectsData from '@/dummies/projectsData'
import GlobalVue from '../libs/Global.vue'

export default {
  extends: GlobalVue,
  data() {
    return {
      counter: 0,
      projectAdded: false,
      dataDuration: 'Y',
      durationOption: [
        { text: 'Jangka Waktu:', value: 'Y' },
        { text: 'Atur Nanti', value: 'N' },
      ],
      durationDate: {},
    }
  },
  methods: {
    addProject() {
      this.counter = 10
      this.$bvModal.show('addProjectProcess')
      projectsData.addData(
        {
          name: 'Proj1',
          keyword: 'Proj1',
          hashtag: 'Proj1',
        }
      )
    },
  },
  watch: {
    counter: {
      handler(val) {
        if (val > 0) {
          this.projectAdded = true
          setTimeout(() => {
            this.counter--
          }, 1000)
        }else if (val == 0 && this.projectAdded) {
          this.$bvModal.hide('addProjectProcess')
          this.$router.push({ name: 'ProjectDashboard', params: { project: _.kebabCase(this.projName) } })
        }
      },
      immediate: true,
    }
  }
}
</script>